import * as React from "react"

import Layout from "../components/layout"
import Office from "../components/office"

const IndexPage = () => {
  return (
    <Layout>
        <div className="site-content container">
            <div className="dsvy-map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10367.13025049911!2d16.896970496121615!3d52.38785381468606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47044529ef59d52f%3A0x7492fbc8a6aa40b9!2sRaszyn.%20Zesp%C3%B3%C5%82%20gabinet%C3%B3w%20lekarskich!5e0!3m2!1spl!2spl!4v1658600861449!5m2!1spl!2spl" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="Raszyn Gabinety Lekarskie, Poznań, ul. Hetmańska 15-19"></iframe>
            </div>
        </div>

        <Office></Office>
    </Layout>          
  )
}

export default IndexPage
